import React from 'react';
import { graphql } from 'gatsby';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { OpenfitLandingPageBySlugQuery } from 'graphql-types';
import { renderLandingPageBlock } from './LandingPage.blocks';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';

export const LandingPageTemplate: React.FC<{
  data: OpenfitLandingPageBySlugQuery;
}> = ({ data }) => {
  const { slug, blocks, seoMetadata } = data.page || {};

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  return (
    <>
      {seoMetadata && slug?.current && (
        <SeoMetadata metaData={seoMetadata} slug={`pages/${slug.current}`} />
      )}
      {blocks
        ?.map((block) => block && renderLandingPageBlock(block))
        .map((block, index) => (
          <ImageLoadingWrapper key={index} eager={index < 2} children={block} />
        ))}
    </>
  );
};

export default LandingPageTemplate;

export const query = graphql`
  query OpenfitLandingPageBySlug($slug: String!) {
    page: sanityOpenfitLandingPage(slug: { current: { eq: $slug } }) {
      name
      optimizelyType
      slug {
        current
      }
      seoMetadata {
        ...SeoMetadata
      }
      blocks {
        ... on SanityEmailSignupBlock {
          ...SanityEmailSignupBlock
        }
        ... on SanityHeroSlider {
          ...SanityHeroSlider
        }
        ... on SanityCollectionSliderBlock {
          ...SanityCollectionSliderBlock
        }
        ... on SanitySubmenu {
          ...SanitySubmenu
        }
        ... on SanityProductSlider {
          ...SanityProductSlider
        }
        ... on SanitySimpleMediaWithTextBlock {
          ...SanitySimpleMediaWithTextBlock
        }
        ... on SanityImageBlock {
          ...SanityImageBlock
        }
        ... on SanitySquareImageSlider {
          ...SanitySquareImageSlider
        }
        ... on SanitySellPageInfoCardsBlock {
          ...SanitySellPageInfoCardsBlock
        }
        ... on SanitySellPageSpacer {
          ...SanitySellPageSpacer
        }
        ... on SanityOptimizelyExperiment {
          ...OptimizelyExperiment
        }
        # todo Uncomment when we will be able to test the grid cards layout
        # ...GridCards
      }
    }
  }
`;

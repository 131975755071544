import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  OpenfitLandingPageBySlugQuery,
  SanityCollectionSliderBlockFragment,
  SanityEmailSignupBlockFragment,
  SanityHeroSlider,
  SanityProductSliderFragment,
  SanitySimpleMediaWithTextBlockFragment,
  SanityImageBlock,
  SanitySquareImageSliderFragment,
  SanitySellPageSpacer,
  OptimizelyExperimentFragment,
  SanitySubmenuFragment,
  SanitySellPageInfoCardsBlockFragment,
  // GridCardsFragment,
} from 'graphql-types';

import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { PDPPageContainer } from 'components/Pdp';
import { SubmenuSanity } from 'components/Common/Submenu/Submenu.sanity';
import { SanityHeroCarousel } from 'components/HeroCarousel/SanityHeroCarousel';
import { SanityInfoCards } from 'components/Common/InfoCards/SanityInfoCards';
import { SanityCollectionSlider } from 'components/CollectionSlider/SanityCollectionSlider';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { ImageBlockSanity } from 'components/ImageBlock/ImageBlock.sanity';
import { SanitySquareImageSlider } from 'components/SquareImageSlider/SanitySquareImageSlider';
import { Spacer } from 'components/Spacer/Spacer';
import { OptimizelyExperiment } from 'components/OptimizelyExperiment/OptimizelyExperiment';
// import { SanityGridCards } from 'components/GridCards/GridCards.sanity';

type BlocksArrayType = NonNullable<
  OpenfitLandingPageBySlugQuery['page']
>['blocks'];

export const renderLandingPageBlock = (block: BlockType<BlocksArrayType>) =>
  renderBlockWithId(block, getBlock(block));

function getBlock(block: BlockType<BlocksArrayType>) {
  const type = block.__typename;
  const id = block._key;

  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanityHeroSlider':
      return (
        <PDPPageContainer>
          <SanityHeroCarousel key={id} fields={block as SanityHeroSlider} />
        </PDPPageContainer>
      );
    case 'SanityCollectionSliderBlock':
      return (
        <SanityCollectionSlider
          key={id}
          {...(block as SanityCollectionSliderBlockFragment)}
        />
      );
    case 'SanitySubmenu':
      return <SubmenuSanity key={id} fields={block as SanitySubmenuFragment} />;
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageInfoCardsBlock':
      return (
        <SanityInfoCards
          key={id}
          fields={block as SanitySellPageInfoCardsBlockFragment}
        />
      );
    case 'SanityProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityProductSliderFragment}
        />
      );
    case 'SanityImageBlock':
      return <ImageBlockSanity key={id} fields={block as SanityImageBlock} />;
    case 'SanitySquareImageSlider':
      return (
        <SanitySquareImageSlider
          key={id}
          fields={block as SanitySquareImageSliderFragment}
        />
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityOptimizelyExperiment':
      return (
        <PDPPageContainer>
          <OptimizelyExperiment
            fields={block as OptimizelyExperimentFragment}
          />
        </PDPPageContainer>
      );
    // case 'SanityGridCards':
    //   return <SanityGridCards fields={block as GridCardsFragment} />;
    default:
      return `Unrecognized block type: ${type}`;
  }
}
